<template>
   <div>
      <v-container fluid>
         <v-row>
            <v-col cols="12" lg="5">
               <v-card v-if="itemsLoading" class="shadow rounded-lg">
                  <v-card-text>
                     <v-row justify="center">
                        <v-col v-for="(el,i) in 4" :key="i" cols="6">
                           <v-card flat>
                              <v-card-text>
                                 <v-skeleton-loader type="article"/>
                              </v-card-text>
                           </v-card>
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
               <v-card v-else class="shadow rounded-lg">
                  <v-card-text>
                     <v-row class="pa-3" justify="center">
                        <v-col class="separate-row separate-column" cols="6">
                           <v-card flat>
                              <v-card-text class="text-center pa-0">
                                 <v-avatar color="blue" size="50">
                                    <v-icon dark size="25">mdi-currency-usd</v-icon>
                                 </v-avatar>
                                 <h3 class="text--primary my-3">
                                    {{ setPriceFormat(items.balance) }} DZD</h3>
                                 <p class="text--secondary text-uppercase">Solde actuel</p>
                              </v-card-text>
                           </v-card>
                        </v-col>
                        <v-col class="separate-column" cols="6">
                           <v-card flat>
                              <v-card-text class="text-center pa-0">
                                 <v-avatar color="success" size="50">
                                    <v-icon dark size="25">mdi-currency-usd</v-icon>
                                 </v-avatar>
                                 <h3 class="text--primary my-3">
                                    {{ setPriceFormat(items.total_amount_transactions) }} DZD </h3>
                                 <p class="text--secondary text-uppercase">Total consomation</p>
                              </v-card-text>
                           </v-card>
                        </v-col>
                        <v-col class="separate-row separate-column" cols="6">
                           <v-card flat>
                              <v-card-text class="text-center pa-0">
                                 <v-avatar color="primary" size="50">
                                    <v-icon dark size="25">mdi-sync</v-icon>
                                 </v-avatar>
                                 <h3 class="text--primary my-3">{{ items.total_transactions }}</h3>
                                 <p class="text--secondary text-uppercase">Total transactions</p>
                              </v-card-text>
                           </v-card>
                        </v-col>
                        <v-col class="separate-column" cols="6">
                           <v-card flat>
                              <v-card-text class="text-center pa-0">
                                 <v-avatar color="error" size="50">
                                    <v-icon dark size="25">mdi-office-building-marker</v-icon>
                                 </v-avatar>
                                 <h3 class="text--primary my-3">{{ items.salepoint_count }}</h3>
                                 <p class="text--secondary text-uppercase">Total point de vente</p>
                              </v-card-text>
                           </v-card>
                        </v-col>
                        <v-col class="separate-row" cols="6">
                           <v-card flat>
                              <v-card-text class="text-center pa-0">
                                 <v-avatar color="blue" size="50">
                                    <v-icon dark size="25">mdi-currency-usd</v-icon>
                                 </v-avatar>
                                 <h3 class="text--primary my-3">
                                    {{ setPriceFormat(items.consumed_alltime) }} DZD
                                 </h3>
                                 <p class="text--secondary text-uppercase">Consomation total</p>
                              </v-card-text>
                           </v-card>
                        </v-col>
                        <v-col cols="6">
                        </v-col>
                     </v-row>
                  </v-card-text>
               </v-card>
            </v-col>
            <v-col cols="12" lg="7">
               <v-card v-if="refillsLoading" class="shadow rounded-lg">
                  <v-card-text>
                     <v-skeleton-loader type="table"/>
                  </v-card-text>
               </v-card>
               <v-card v-else class="shadow rounded-lg">

                  <v-card-text v-if="!refillsLoading && refills.length > 0">
                     <v-simple-table>
                        <template v-slot:default>
                           <thead>
                           <tr>
                              <th class="text-left">Desinataire</th>
                              <th class="text-left">Jour</th>
                              <th class="text-left">Montant</th>
                              <th class="text-left">Etat</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr v-for="item in refills" :key="item.id">
                              <td>{{ item.recipient.name }}</td>
                              <td>
                                 <v-icon dense>mdi-clock-outline</v-icon>
                                 {{ item.created_at | moment("Do/MM/YYYY, h:mm") }}
                              </td>
                              <td>
                                         <span class="blue--text font-weight-medium">
                                           {{ setPriceFormat(item.amount) }} DZD
                                         </span>
                              </td>
                              <td>
                                 <v-chip :color="setStatusColor(item.state)" dark small>
                                    {{ setStatus(item.state) }}
                                 </v-chip>
                              </td>
                           </tr>
                           </tbody>
                        </template>
                     </v-simple-table>
                     <div class="d-flex mt-4">
                        <v-spacer/>
                        <v-pagination v-if="total>10"
                                      v-model="pagination.current"
                                      :length="pagination.total"
                                      circle
                                      total-visible="10"
                                      @input="onPageChange"
                        ></v-pagination>
                     </div>
                  </v-card-text>

                  <v-card-text v-else class="text-center">
                     <NoResult/>
                  </v-card-text>

               </v-card>
            </v-col>
         </v-row>
      </v-container>
   </div>
</template>

<script>

import axios from "axios";
import NoResult from "@/components/NoResult.vue";

export default {
    components: {NoResult},

    data() {
        return {
            itemsLoading: false,
            refillsLoading: false,
            items: {},
            key: process.env.VUE_APP_GIFTY_API_KEY,
            listUrl: process.env.VUE_APP_GIFTYLIST_API_URL,
            refillUrl: process.env.VUE_APP_GIFTYREFILL_API_URL,
            refills: [],
            total: 0,
            pagination: {
                current: 1,
                total: 0
            },
        }
    },
    methods: {
        getItems() {
            this.itemsLoading = true
            axios.get(this.listUrl + '/' + this.key).then(res => {
                this.itemsLoading = false
                this.items = res.data
            }).catch(err => {
                this.itemsLoading = false
                console.log(err)
            })
        },
        getRefills() {
            this.refillsLoading = true
            axios.get(this.refillUrl + '/' + this.key + '?page=' + this.pagination.current).then(res => {
                this.refillsLoading = false
                this.refills = res.data.data
                this.pagination.current = res.data.current_page;
                this.pagination.total = res.data.last_page;
                this.total = res.data.total;
            }).catch(err => {
                this.refillsLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getRefills();
        },
        setPriceFormat(price) {
            if (price == null) {
                price = 0
            }
            return parseInt(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        },
        setStatusColor(status) {
            switch (status) {
                case 'verification':
                    return 'info lighten-1'
                case 'refused':
                    return 'red'
                case 'pending':
                    return 'orange accent-2'
                case 'accepted':
                    return 'success'
            }
        },
        setStatus(status) {
            switch (status) {
                case 'verification':
                    return 'Vérification'
                case 'refused':
                    return 'Refusé'
                case 'pending':
                    return 'En attendant'
                case 'accepted':
                    return 'Accepté'
            }
        }
    },
    created() {
        this.getItems()
        this.getRefills()
    }
}
</script>

<style scoped>
.theme--light .separate-row {
    border-right: 1px solid #eee;
}

.theme--light .separate-column {
    border-bottom: 1px solid #eee;
}

.theme--dark .separate-row {
    border-right: 1px solid hsla(0, 0%, 100%, .2);
}

.theme--dark .separate-column {
    border-bottom: 1px solid hsla(0, 0%, 100%, .2)
}
</style>